import React from 'react'
import { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from './firebase';
import AdminDashboardNav from '../AdminPanel/AdminDashboardNav';
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../Db/firebase";

const DowloadPanelBookDB = () => {


  let navigate = useNavigate();

  let id;
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {

      if (user) {
        id = user.uid;
        navigate('/download-panel-book-database');
        console.log("uid", id);
      } else {
        navigate('/admin-login');
        console.log("user is logged out")
      }
    });
  }, [])

  // fetching data
  const [todos, setTodos] = useState([]);


  const fetchPost = async () => {

    await getDocs(collection(db, "Download_Panel_Book"))
      .then((querySnapshot) => {
        const newData = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        setTodos(newData);
        console.log(todos, newData);
      })

  }

  useEffect(() => {
    fetchPost();
  }, [])

  return (
    <div>
      <div class='dashboard'>
        <AdminDashboardNav />
        <div class='dashboard-app'>
          <section className="dashboard-container">
            <h1>Download Panel Book Form Responses</h1>

            <div className="dashboard-content">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Country</th>
                  </tr>
                </thead>
                {
                  todos?.map((todo, i) => (
                    <tbody key={i}>
                      <tr>
                        <td data-column="First Name">{todo.Name}</td>
                        <td data-column="Last Name">{todo.Company_Name}</td>
                        <td data-column="Job Title" style={{ textTransform: 'lowercase' }}>{todo.Email}</td>
                        <td data-column="Twitter">{todo.Country}</td>
                      </tr>

                    </tbody>
                  ))
                }
              </table>
             
            </div>
          </section>
        </div>

      </div>
    </div>
  )
}

export default DowloadPanelBookDB
