import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Db/firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import './admin.scss'

const AdminLogin = () => {

    //fetching data from form

    const [userData, setUserData] = useState({
        Email: "",
        Password: ""

    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //fetching data from form ends

    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, userData.Email, userData.Password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/admin")
                console.log(user);
                alert("logged in Successfully")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                alert(errorCode, errorMessage);
            });

    }

    return (
        <div>
            <section className='auth'>
                <div>
                    <div class="container">
                        <div class=" text-center title">Admin Login</div>
                        <form onSubmit={onLogin}>
                            <div class="user__details">

                                <div class="input__box">
                                    <span class="details">Email</span>
                                    <input
                                        type="email"
                                        name='Email'
                                        placeholder="johnsmith@gmail.com"
                                        label="Email address"
                                        value={userData.Email}
                                        onChange={postUserData}
                                        required />
                                </div>

                                <div class="input__box">
                                    <span class="details">Password</span>
                                    <input type="password"
                                        name='Password'
                                        placeholder="********"
                                        label="Create password"
                                        value={userData.Password}
                                        onChange={postUserData}
                                        required />
                                </div>


                            </div>

                            <div class="button">
                                <input
                                    type="submit"
                                    value="Login" />
                            </div>
                        </form>
                        <p className="text-sm text-center">
                            No account yet? {' '}
                            <NavLink to="/employee_signup">
                                Sign up
                            </NavLink>
                        </p>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default AdminLogin
