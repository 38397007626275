import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {

    const [open, setOpen] = useState('none');

    const show = () => {
        setOpen("block");
    }

    const hide = () => {
        setOpen("none");
    }

    return (
        <>
            <div className="header">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512543/Think_Tank/Logo/TTR_Logo_oqhg4l_av9kaj.webp" alt="logo" />
                <ul className="menu-bar">
                    <Link to="/"> <li>home</li></Link>
                    <Link to="/about"> <li>about us</li></Link>
                    <Link to="/services"> <li>services</li></Link>
                    <Link to="/contact_us"> <li>contact us</li></Link>
                    <a href="https://www.opinion-matters.com/" target='_blank'><li>join our panel</li></a>
                </ul>
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512540/Think_Tank/Icons/menu-blue_jhbco2_n0agt2.webp" alt="menu" className="menu-icon" onClick={show} />

            </div>
            <div className="header-mobile" id="mobile-menu" style={{ display: open }}>
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512533/Think_Tank/Icons/close_ihrols_snje2a.webp" alt="menu" className="menu-icon" onClick={hide} />
                <ul className="menu-bar" >
                    <Link to="/"><li>home</li></Link>
                    <Link to="/about"> <li>about us</li></Link>
                    <Link to="/services"> <li>services</li></Link>
                    <Link to="/contact_us"> <li>contact us</li></Link>
                    <a href="https://www.opinion-matters.com/" target='_blank'><li>join our panel</li></a>
                </ul>
            </div>

        </>
    )
}

export default Header
