import React from 'react'
import { Link } from 'react-router-dom'

import { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../Db/firebase';

const Footer = () => {
    // google translator

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                autoDisplay: false
            },
            "google_translate_element"
        );
    };


    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    // google translator ends

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Company_Name: "",
        Phone: "",
        Requirements: ""

    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }
    //connect with firebase

    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Company_Name, Phone, Requirements } = userData;
        if (Name && Email && Company_Name && Phone && Requirements) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                alert('Please enter a valid email address');
                return
            }

            if (typeof Phone !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Phone)) {
                    alert("Please enter only number.");
                    return

                } else if (userData.Phone.length !== 10) {
                    alert("Please enter 10 digit phone number.");
                    return
                }
            }

            try {

                const docRef = await addDoc(collection(db, "Contact_Form"), {
                    Name: Name,
                    Email: Email,
                    Company_Name: Company_Name,
                    Phone: Phone,
                    Requirements: Requirements
                });


                emailjs.sendForm('service_ju68frs', 'template_u8x0f4g', form.current, 'UaxECUTUw4qjN2AcT')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                alert('Message Sent Successfully');

                setUserData({
                    Name: "",
                    Email: "",
                    Company_Name: "",
                    Phone: "",
                    Requirements: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                alert("Error adding document")
            }
        }
        else {
            alert('Please fill the data');
        }
    };

    return (
        <>
            <div id="footer">
                <img className="footer-bg" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512533/Think_Tank/Footer/png-01_uzizcs_aekplx.webp" alt="" />
                <footer>
                    <div className="footer-content left">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512541/Think_Tank/Logo/footer_Logo_rpmhy2_da122q.webp" alt="logo" />
                        <h6>THINK TANK RESEARCH GROUP</h6>
                        <div className="footer-left-body">
                            <h4>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADdAAAA3QFwU6IHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAThQTFRF////+5CWpG6Y+4WMum+RlGOUQUebQkibQkicRkueRkydR02dR02eSE2eSE6eSE6fSU+fSlCfSlCgTFGgTFKhTVKgTVOhTlOhTlSiT1WiUVWhUVajUVejUkeVUkqXUk6bUlGeUlOgUlejUlikU0eUU1ikVFqkVVqlVlumV1ymWV6nXGGoXmOpX2SqYGWqYGWrYWarYmesY2isZE2TZGmsZmqtZmuuZ2uuaG2uaW2vam+va3CwbHGxbXGxbXKxbnOyb3OycHSycHWzcXWzcnazdHi0dXm1d3y2eHy3en64e3+4fIC4foK6gl2WiIy/io7Ai4/AjZDBkFaKlZjFl5rGmJvGmZzHnaDJoKLKoKPLoaTLoqTLo6bMpKfMpafNpajN9fDw9fX19uDh+Lq++MrN+5CW/Wpy/0tV1Uw64AAAAAZ0Uk5TAIqwuMzZ24+8ogAAAOpJREFUOMtjYBgKgIkVGbAwowFGBjZ+NjYeOFJJRwcMHPE8vHECXLECnFEC3DFSSeiAgU3E1VHY3V7Yy0bYx1JYMQUdMHBECwlGifJFivOEi/FFyGJawabkYqfqZqfkbq3kZaGkkIwOGDjClGVC1SRDNSSCNSRDpBPQAQO7gZW5ga25gYOZvouRPjYrArU0A7TV/XVV/fRV/eVT0QEDh7OhnrOJrrOpnpOFjpNMIjpgYPNmY/MEIw8gKZeGDhh8jZFBEKaCRAKAIZUAYEgnABgSCACGZAKAsBUpBABDEgFA2ApGZryAcUjkPABWg9YaCJi69wAAAABJRU5ErkJggg==" alt="US" />&emsp;
                                United States
                            </h4>
                            <p>Broadway, 2nd Floor Suite #1637, New York - 10013</p>
                        </div>
                        <div className="footer-left-body">
                            <h4>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABMpJREFUWIXtlmtQ1FUYxn/7ZwF3cZcVMC6yEAMIRFimhE6F2iSpoy4qRqYh4f0SoQniBWPSCWY0tRy0lNBRSh1LAUlqKkfIG4poKoJCiNxBLsttubiXPpSoAeKQM/aB37fznnnO87xz3plzoJ9+njGi9BVf1nhGvpshNjXR5ZZppJ8du+Om1mhNexJMHG5ZtnS8XUnd5CWjHq5bpO46v/OXcmXa5dohvZkOtZU0bFDZF4i//tZelIebQe/sSP3CIDwXTaalXceOn8rYfqKUtnv6LuKPJyuJmu5IpcLnkbqNOpONR+/weWpJj8aWMmPCpygJNiqhOWoH2uv5CA0zp2JUXIbl6o0UT1xGVWYukSoHsmNHEjzGBkEk6q2hXpGYCIRNsic7SEHgzljU00LR3byNdMFMhO/8ZqNO2ovmrTGYnMtC6r+AwoAIKKti+1wXTka9xGtu5n0yFkQi/L2tuBDqyMrTB2n1+4D2k5mYjH0Vy4wDyDevQvgirZQJh+pICQmlPjEO7TAPpL+mYzR+NrmhW3GW6vlxtRdJq17EY4j0ic3HvqAgfbUHce0XMPGbg2ZfEsZerlik7sIiaQeNSiWRBwsRMT3VcF/kbC1hvb8DrpczsYqLh5IKsFBQEzwLjzVz0AtGFN1tw9VG0uMMHL9UyzqVPW8XZtEUHYeusgbB7jkGRsxDGjSVDj0knKokJqmYBo320QD38XaW8alKiexoClbxidDYxMODCnQb4HZ1G7Y5f3QOmMhMgtmHcxgY9j4GU1OSs2qI/r6IorttnTpR0tmiLgEARCLwcZHTWlFH1bmcB0a+w1DYDuo2gF7dhPZKbmdN7DUUwVLxd2B1B3nlmq4+arW62wC90V2AviDKw61PAZ4WwrM07w8A/4MhFJ+6oe5SdLeTYqMwAUBfq0Z77dYDwcseCApZt4epK+qpzLjaubYe7YnE1oLMgkYMPbQp9t9yvXMxxMKU8ClKfD3MMXTcozXhKE0xezA0NCF2c0IaOZ+yDmOceuhGYTuISkC+bTdCXgEdZhIqZgUgDQkk+nglZ242dNEIAGamRkSqHLgUM4JgX2s6kk9S4/0OjZFbEYmNkMeuJOebOPyuWJJ4uqoHe8ivbMU5wBebM/upiY7AYCbFIv4AyhkhxJJD8goP3O0efU+ExePtuLZ5JJEqB4QrN6ibsBB18Fr01bWYhQWhTkskpMML1dZcrpe09GgOcOhsNaOjsknJrsfzoxnIL/5A7bJ5iJqaGRyzDZfFYSQMb2X7XBcGy42Bf4ZQW1BM86avaEv6DQSBAVPH0Ra+hNisdg78XoVO/+ACn/RD4u0sY1OgEz4ucqpvldMQswdZys+g09E+agQtEUs5XDsQcWP4Flr3HcNwT4vpmz4Yb1hOfLmULXElNLfpHtvx47j4ZxMTYq6iGmlFdMDzuO79hMKMGYhjdmF6LosBgYt4z38SYs2eI4jdnDCLnE+y9TCi9xdRqa7us/HDGAyQdLGGE5frCBlnwxqVO+ZpceQdTke+bTfmR1IQy9YtvKRdNLt0fXKpy638KnNXGwmuNpIeD7WUGZcBJSavvzLqX1vnLWXGyjfczbv9lOaUtLA8Ib997TTHmx6BYzRa/9FGObGHfJ9Kp/3081/4Cyq06zVl+gQNAAAAAElFTkSuQmCC" alt="India" />&emsp;
                                United Kingdom
                            </h4>
                            <p>The Kell, Gillingham Gate Road, Gillingham ME4&nbsp;4SH, Gillingham, England&nbsp;ME4&nbsp;4SH,&nbsp;GB</p>
                        </div>
                        <div className="footer-left-body">
                            <h4>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADdAAAA3QFwU6IHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAADBQTFRF////c64A+bRIc64A+7VGyt6j9t+6c68AiIy/iY2/rK7QuLrWvb7Y8/P09fX1+rRGRQvhSgAAAAd0Uk5TAFJSeHjAwPIGe2oAAABGSURBVCiRY2CgBVBxQQFODP/RAG0E0tAAwzsweLtz9j0ICyrwes2pfSgCL0/0zMMvgKEFydBQNMBQjgZoIyBsjAIMaRKTAEaT9D8P6559AAAAAElFTkSuQmCC" alt="India" />&emsp;
                                India
                            </h4>
                            <p>Plot No.38, Bigshark Business Center, Golf Course Rd, Suncity, Sector 54, Gurugram, Haryana 122011</p>
                        </div>
                    </div>
                    <div className="footer-content middle">
                        <form ref={form} method="POST">
                            <input
                                type="text"
                                name="Name"
                                placeholder="Your Name"

                                value={userData.Name}
                                onChange={postUserData}
                                required />
                            <input
                                type="email"
                                name="Email"
                                placeholder="Email Address"

                                value={userData.Email}
                                onChange={postUserData}
                                required />
                            <input
                                type="text"
                                name="Phone"
                                placeholder="Phone Number"

                                value={userData.Phone}
                                onChange={postUserData}
                                required />
                            <input
                                type="text"
                                name="Company_Name"
                                placeholder="Company Name"

                                value={userData.Company_Name}
                                onChange={postUserData}
                                required />
                            <textarea type="text"
                                name="Requirements"
                                placeholder="Requirements"

                                value={userData.Requirements}
                                onChange={postUserData}
                                required></textarea>
                            <button onClick={SubmitData}>Connect With Us</button>
                        </form>
                    </div>
                    <div className="footer-content right">
                        <h3>get in touch</h3>
                        <p><b>Office Telephone (US): </b>
                            <a href="tel:+13152280138">+1 (315) 228-0138</a>
                        </p>
                        <p><b>Office Telephone (IN): </b>
                            <a href="tel:+918384087587">+91 8384087587</a>
                        </p>
                        <p><b>Email: </b>
                            <a href="mailto:contact@thinktankresearch.co">contact@thinktankresearch.co</a>
                        </p>
                        <p><b>Email: </b>
                            <a href="mailto:sales@thinktankresearch.co">sales@thinktankresearch.co</a>
                        </p>
                        <h3>Follow Us</h3>
                        <div className="footer-follow">
                            <a href="https://www.google.com/search?q=thinktank+research+group&amp;rlz=1C1VDKB_enIN1053IN1053&amp;oq=ThinkTank+Research+Group&amp;gs_lcrp=EgZjaHJvbWUqBwgAEAAYgAQyBwgAEAAYgAQyCggBEAAYhgMYigUyCggCEAAYhgMYigUyBggDEEUYPDIGCAQQRRg8MgYIBRBFGDzSAQc2MzRqMGo3qAIAsAIA&amp;sourceid=chrome&amp;ie=UTF-8" target="_blank">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M881 442.4H519.7v148.5h206.4c-8.9 48-35.9 88.6-76.6 115.8-34.4 23-78.3 36.6-129.9 36.6-99.9 0-184.4-67.5-214.6-158.2-7.6-23-12-47.6-12-72.9s4.4-49.9 12-72.9c30.3-90.6 114.8-158.1 214.7-158.1 56.3 0 106.8 19.4 146.6 57.4l110-110.1c-66.5-62-153.2-100-256.6-100-149.9 0-279.6 86-342.7 211.4-26 51.8-40.8 110.4-40.8 172.4S151 632.8 177 684.6C240.1 810 369.8 896 519.7 896c103.6 0 190.4-34.4 253.8-93 72.5-66.8 114.4-165.2 114.4-282.1 0-27.2-2.4-53.3-6.9-78.5z">
                                </path>
                                </svg>
                            </a>
                            <a href="https://in.linkedin.com/company/thinktank-research-group" target="_blank">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z"></path>
                                </svg>
                            </a>

                            <a href="" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>                            </a>

                            <a href="https://www.instagram.com/thinktank_research/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                            </a>

                        </div>

                        <div className="footer-follow">
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695734799/Think_Tank/Footer/1_erkkta.png" alt="ISO 9001:2015" title='ISO 9001:2015' />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695734799/Think_Tank/Footer/2_wdoxxx.png" alt="ISO 27001:2013" title='ISO 27001:2013' />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695734800/Think_Tank/Footer/3_ogwfba.png" alt="ISO 20252:2012" title='ISO 20252:2012' />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695706241/Think_Tank/Footer/SSL_m4wine.png" alt="SSL Secure" title='SSL Secure' />
                        </div>
                    </div>
                </footer>
                <div className="copyright">
                    <p>Copyright © 2023. All Rights Reserved By <span>Think Tank Research Group</span>  <Link to={'/privacy-policy'}>Privacy Policy</Link></p>
                    <div id="google_translate_element"></div>
                </div>

            </div>
        </>
    )
}

export default Footer
