import React, { useEffect, useState } from 'react'
import AdminDashboardNav from '../AdminPanel/AdminDashboardNav';
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../Db/firebase";


const Profile = () => {

    let navigate = useNavigate();

    let id;
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {

            if (user) {
                id = user.uid;
                navigate('/admin-profile');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });

    }, [])



    return (
        <div className='profile'>
            <div class='dashboard'>
                <AdminDashboardNav />
                <div class='dashboard-app'>
                    <section className="dashboard-container">
                        <h1>Profile</h1>
                        <form action="POST">
                            <div>
                                <label htmlFor="">Change Password</label>
                                <label htmlFor="">Confirm Password</label>
                            </div>
                            <div>
                                <input
                                    type="password"
                                    name="password"
                                />
                                <input
                                    type="password"
                                    name="confirm-password"
                                />
                                <button>Change Password</button>

                            </div>
                        </form>

                    </section>
                </div>

            </div>
        </div>
    )
}

export default Profile
