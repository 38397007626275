import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom'
import { auth } from '../Db/firebase';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";

const AdminDashboardNav = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/admin-login");
            console.log("Signed out successfully");
            alert("Logged out successfully")
        }).catch((error) => {
            console.log(error);
            alert(error);
        });
    }

    return (

        <div class="dashboard-nav">
            <header>
                <Link to={"/admin"}>
                    <div className="a-logo">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1694512541/Think_Tank/Logo/footer_Logo_rpmhy2_da122q.webp" alt="" />
                    </div>
                </Link>
            </header>
            <nav class="dashboard-nav-list">
                <Link to={"/admin"} class="dashboard-nav-item">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg>
                    &nbsp;
                    Home
                </Link>

                <a href="#" class="dashboard-nav-item">
                    <Accordion>
                        <Accordion.Item eventKey="0" tabIndex="1">
                            <Accordion.Header>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" /></svg>
                                &nbsp;
                                Database
                            </Accordion.Header>
                            <Accordion.Body>
                                <Link to={"/contact-form-database"} class="dashboard-nav-dropdown-item">Contact Form</Link>
                                <Link to={"/download-panel-book-database"} class="dashboard-nav-dropdown-item">Download Panel Book Form</Link>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </a>
                <Link to={"/admin-profile"} class="dashboard-nav-item">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                    &nbsp;
                    Profile
                </Link>

                <button class="logout" onClick={handleLogout}>
                    Logout &nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" /></svg>

                </button>
            </nav>
        </div>

    )
}

export default AdminDashboardNav
