import './admin.scss'
import AdminDashboardNav from './AdminDashboardNav';
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
// import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../Db/firebase";

const Admin = () => {

  let id;
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {

      if (user) {
        id = user.uid;
        navigate('/admin');
        console.log("uid", id);
      } else {
        navigate('/admin-login');
        console.log("user is logged out")
      }
    });
  }, [])

  let navigate = useNavigate();

  return (
    <div className='admin'>
      <div class='dashboard'>
        <AdminDashboardNav />
        <div className="dashboard-app">
          <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695993435/Think_Tank/Admin%20Panel/Welcome_to_the_Admin_Panel_w9fm3z.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Admin
