import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Banner from '../Utilities/Banner'
import { Helmet } from 'react-helmet'
import { AnimationOnScroll } from 'react-animation-on-scroll';


const Services = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, []);

  return (
    <div>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Market Research Services | Thinktank Research Group</title>
        <link rel="canonical" href="" />
        <meta name="description" content="Drive your business through our comprehensive market research solutions. As a leading market research solution provider, we offer tailored strategies and data-driven analysis to help you make informed decisions and stay ahead in a competitive landscape." />
      </Helmet>
      <div className="service-page">

        {/* banner */}
        <div className="banner service-banner">
          <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695121888/Think_Tank/Services/Data_Collection_-_Banner_cvriii.webp" alt="banner" />
          <div className="banner-caption">
            <h3>Data Collection Services</h3>
            <p>Proprietary Panel and Trusted Affiliates</p>
          </div>
        </div>
        {/* banner ends */}

        {/* service section */}


        <AnimationOnScroll animateIn="animate__flipInX">

          <div id="service-section">
            <div className="service-headline">
              <span>our features and offerings</span>
              <h3></h3>
            </div>
            <div id='case-study'>

              <div className="case-study">
                <div className="case-study-card">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695966234/Think_Tank/Services/doi_panel_udcrii.png" alt="DOI Panel Assurance" />
                  <div className="case-study-card-caption">
                    <p>DOI Panel Assurance</p>
                    <div className="case-study-hidden">
                      <ul>
                        <li><b>Stringent Quality Assessments: </b>We maintain the integrity of our DOI (Declaration of Intent) Panel through rigorous quality assessments.</li>
                        <li><b>Mandatory DOI Process: </b>All panelists are required to complete the DOI process as part of their panel membership.</li>
                        <li><b>Response Accountability: </b>Respondents providing unacceptable answers thrice face exclusion from future surveys, ensuring data reliability.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="case-study-card">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695966810/Think_Tank/Services/npf_imlyzf.png" alt="No Project Minimum Fees (PMF)" />
                  <div className="case-study-card-caption">
                    <p>No Project Minimum Fees (PMF)</p>
                    <div className="case-study-hidden">
                      <ul>
                        <li><b>Cost-Effective Operations: </b>Our streamlined operations and cost-effective approach enable us to eliminate Project Minimum Fees (PMF).</li>
                        <li><b>Transparent Pricing: </b>We provide CPI-based pricing to our clients with no project minimums, setup fees, or additional project management charges.</li>
                        <li><b></b></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="case-study-card">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695966234/Think_Tank/Services/re-contact_sxtfcp.png" alt="Re-Contact Project Advantage" />
                  <div className="case-study-card-caption">
                    <p>Re-Contact Project Advantage</p>

                    <div className="case-study-hidden">
                      <ul>
                        <li><b>Responsive Panelists: </b>Our panel comprises responsive and cooperative participants, enhancing our re-contact rate*</li>
                        <li><b>Data Consistency: </b>Willingness and engagement of panel members to contribute to recontact fieldworks</li>
                        <li><b></b></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="case-study-card">
                  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695966234/Think_Tank/Services/24x7_eu0lt6.png" alt="24/7 Project Coverage" />
                  <div className="case-study-card-caption">
                    <p>24/7 Project Coverage</p>
                    <div className="case-study-hidden">
                      <ul>
                        <li><b>Dedicated Project Management: </b>Your project benefits from continuous support with our dedicated Project Management Team available around the clock.</li>
                        <li><b>Timely Assistance: </b>We ensure your project receives 24/7 coverage, delivering prompt and efficient support for your research needs.</li>
                        <li><b></b></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </AnimationOnScroll>
        {/* service section ends */}

        {/* service-service */}
        <AnimationOnScroll animateIn="animate__rotateInDownLeft">
          <div id="service-service">
            <div className="service-headline">
              <span>our services</span>
              <h3></h3>
            </div>
            <div className="service-service">

              <div className="service-service-body">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695102720/Think_Tank/Services/Services_Data_Collection_lf5qth.webp" alt='data collection' />
                <Link to={"/contact_us"}><button>know<br /> more</button></Link>
                <div className="service-service-text">
                  <h4>Data Collection</h4>
                  <ul>
                    <li>Qualitative Research</li>
                    <li>Quantitative Research</li>
                    <li>Business to Business - B2B</li>
                    <li>Business to Consumer - B2C</li>
                    <li>Health Care & Ailment</li>
                  </ul>
                </div>
              </div>

              <div className="service-service-body">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695102721/Think_Tank/Services/Services_End_to_end_research_ifjrsj.webp" alt="end to end research solutions" />
                <Link to={"/contact_us"}><button>know<br /> more</button></Link>
                <div className="service-service-text end">
                  <h4>End-To-End Research Solutions</h4>
                  <ul>
                    <li>Questionnaire Design</li>
                    <li>Survey Programming & Hosting</li>
                    <li>Data Collection & Data Analysis</li>
                    <li>Reporting and Charting</li>
                  </ul>
                </div>
              </div>

              <div className="service-service-body">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695102723/Think_Tank/Services/Services_Project_Management_yzfsjp.webp" alt="project management" />
                <Link to={"/contact_us"}><button>know<br /> more</button></Link>
                <div className="service-service-text">
                  <h4>Project Management</h4>
                  <ul>
                    <li>15 Hours of PM Coverage including holidays and weekends</li>
                    <li>Quality Control</li>
                    <li>Experienced Professionals</li>
                    <li>Human Review</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
        {/* service-service ends */}

        {/* why-us */}
        <AnimationOnScroll animateIn="animate__slideInLeft">
          <div id='why-us'>
            <div className="service-headline">
              <span>what sets us apart?</span>
              <h3></h3>
            </div>
            <section id="features" className="features-area item-full text-center cell-items default-padding">
              <div className="container">
                <div className="row features-items">
                  <div className="col-md-4 col-sm-4 equal-height">
                    <div className="item">
                      <div className="icon">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695280192/Think_Tank/Services/medal_10436720_iykiqn.png" alt="our strength" />
                      </div>
                      <div className="info">
                        <h4>Our strength</h4>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been */}
                        {/* </p> */}
                        <ul>
                          <li>Swift Delivery – TAT of 30 minutes from project win to infield*</li>
                          <li>Round-the-Clock Coverage</li>
                          <li>Dedicated Project Management Team for seamless fieldwork</li>
                          <li>Capability to Meet Tight Deadlines</li>
                          <li>No Minimum Project Fee</li>
                          <li>Responsive Panel with a 15% - 20% response rate</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 equal-height">
                    <div className="item">
                      <div className="icon">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695280194/Think_Tank/Services/like_880605_ke3xna.png" alt="Quality Assurance" />
                      </div>
                      <div className="info">
                        <h4>Quality Assurance</h4>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                    </p> */}
                        <ul>
                          <li>Stringent Recruitment Process</li>
                          <li>Multi-modal Verification</li>
                          <li>Ongoing Scrutiny of Potential Fraudsters</li>
                          <li>Strict Opt-in Verification</li>
                          <li>Utilization of Geo IP Detection & Duping Filters</li>
                          <li>Speed Checks for Data Accuracy</li>
                          <li>Implementation of Red Herring & Knowledge Check/Trap Questions</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 ">
                    <div className="item">
                      <div className="icon">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695280194/Think_Tank/Services/people_9968880_pe5s04.png" alt="Our Dynamic Team" />
                      </div>
                      <div className="info">
                        <h4>Our Dynamic Team</h4>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                    </p> */
                          <ul>
                            <li>Comprises a Diverse Group of Team Members</li>
                            <li>Professionals with a Strong Commitment to Excellence</li>
                            <li>Leverages Extensive Experience to Expedite Turnaround Time (TAT)</li>
                          </ul>}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>

        </AnimationOnScroll>
        {/* why-us ends */}

        {/* case study */}
        <AnimationOnScroll animateIn="animate__slideInRight">
          <div id='case-study'>
            <div className="service-headline">
              <span>case studies</span>
              <h3></h3>
            </div>
            <div className="case-study">
              <div className="case-study-card">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695106909/Think_Tank/Services/b2b_ero1cx.jpg" alt="b2b" />
                <div className="case-study-card-caption">
                  <h4>b2b</h4>
                  <p>SMB business seller<br /> case study</p>
                  <div className="case-study-hidden">
                    <ul>
                      <li><b>Project Type: </b>B2B</li>
                      <li><b>Project Targeting: </b>Small/Medium business sellers, mix of ebay active and competitor</li>
                      <li><b>Markets: </b>US, UK & DE</li>
                      <li><b>In-Field Specs: </b>Final N - 436, IR - 11%, LOI - 18min</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="case-study-card">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695106910/Think_Tank/Services/b2c_g6nmwy.jpg" alt="b2c" />
                <div className="case-study-card-caption">
                  <h4>b2c</h4>
                  <p>consumer shopping behavior case study</p>
                  <div className="case-study-hidden">
                    <ul>
                      <li><b>Project Type: </b>B2C</li>
                      <li><b>Project Targeting: </b>Consumer / Shoppers 18+yo who have shopped in-store or online in the past 4 weeks</li>
                      <li><b>Markets: </b>US, CA, CL, BR, MX, CO, UK, FR, DE, IN, JP & AU/NZ</li>
                      <li><b>In-Field Specs: </b>Final N - 788, IR - 51%, LOI - 19min</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="case-study-card">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1695106908/Think_Tank/Services/hcp_yfna3q.jpg" alt="hcp" />
                <div className="case-study-card-caption">
                  <h4>hcp</h4>
                  <p>urologists & oncologists case study</p>

                  <div className="case-study-hidden">
                    <ul>
                      <li><b>Project Type: </b>HCP</li>
                      <li><b>Project Targeting: </b>Urologists and Oncologists</li>
                      <li><b>Markets: </b>CA, FR, DE & BR</li>
                      <li><b>In-Field Specs: </b>Final N - 164, IR - 14%, LOI - 29min</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </AnimationOnScroll>
        {/*  case study ends */}

      </div>

      <Footer />
    </div>
  )
}

export default Services
