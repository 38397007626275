import "./Components/Sass/style.scss";
import "./Components/Sass/media.scss";
import "./Components/Sass/animations.scss";

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Components/Pages/Home"
import About from "./Components/Pages/About"
import Services from "./Components/Pages/Services"
import Contact from "./Components/Pages/Contact";
import Privacy_Policy from "./Components/Pages/Privacy_Policy";
import AdminLogin from "./Components/AdminPanel/AdminLogin";
import Admin from "./Components/AdminPanel/Admin";
import ContactFormDB from "./Components/Db/ContactFormDB";
import DowloadPanelBookDB from "./Components/Db/DowloadPanelBookDB";
import Profile from "./Components/AdminPanel/Profile";


function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact_us' element={<Contact />} />
          <Route path='/privacy-policy' element={<Privacy_Policy />} />

          {/* Admin panel */}
          <Route path='/admin-login' element={<AdminLogin />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/contact-form-database" element={<ContactFormDB />} />
          <Route path="/download-panel-book-database" element={<DowloadPanelBookDB />} />
          <Route path="/admin-profile" element={<Profile />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
