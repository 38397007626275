import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyDQREx8QjXTBma90OdsDoM35aUVUVWaDc4",
    authDomain: "think-tank-5ea53.firebaseapp.com",
    projectId: "think-tank-5ea53",
    storageBucket: "think-tank-5ea53.appspot.com",
    messagingSenderId: "192795750319",
    appId: "1:192795750319:web:faf396756b047e72bc095a",
    measurementId: "G-ZRZQTQ70BG"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);