import React, {useEffect} from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'

const Privacy_Policy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Header />
            <div className='privacy'>

                <h1>Privacy Policy for ThinkTank Research</h1>


                <div>
                    <h3>Your Privacy</h3>
                    <p>Thinktank Research Group Inc. (“TTR”) is committed to respecting the privacy of individuals who participate in surveys or use its online services. TTR’s Privacy Policy outlines the procedures for collecting, using, storing, and safeguarding information obtained from survey respondents and online users of its services. With the exception of disclosing survey responses to TTR’s clients who have commissioned the surveys or when legally obligated.</p>
                    <p>TTR does not disclose Personally Identifiable Information (PII) about survey respondents to third parties. PII refers to information that can be used to identify an individual, such as their name, address, or email address.</p>
                </div>


                <div>
                    <h3>Consent</h3>
                    <p>Your consent is the most important part of any transaction involving both the information you’ve previously provided TTR and any request for information.</p>
                    <p>TTR is committed to protecting your privacy and will not reveal any non-aggregated or non- anonymized information that you have shared with us without your explicit consent. This applies to the specific information you have provided us, as opposed to depersonalized data sets that use your information as part of a larger set without identifying you individually.</p>
                    <p>Request for your PII will generally be preceded by messaging that informs you of this request, its purpose, and the proposed use(s), so you can decide if you want to provide consent.</p>
                    <p>Before you give your consent to provide Personally Identifiable Information (PII), you have the right to know which entities or organizations can access and use the information you provide. You also have the right to be informed about how your information will be used.</p>
                </div>

                <div>
                    <h3>Collected Information</h3>
                    <p>TTR gathers diverse information about survey respondents based on the requirements of each survey. This may include personally identifiable, financial, or demographic data, as well as opinions on various products and services. Participation in the surveys is voluntary, and respondents can choose to answer only the questions they are comfortable with. Declining to answer any survey questions is also an option for survey respondents.</p>
                    <p>TTR may share the information collected from surveys, such as demographic data and preferences, with the clients who have commissioned the surveys. By participating in the survey, you acknowledge and agree that any information you voluntarily provide may be shared with the survey commissioner. TTR will not disclose any personally identifiable information of survey respondents to anyone, except with the respondent’s consent or when required by law.</p>
                    <p>You have the right to the information and data you have provided TTR. You may request a copy of the information and data you provide, and TTR will provide a copy of your information to you in a timely manner, subject to exceptions under applicable law.</p>
                </div>
                <div>
                    <h3>Additional Information from Survey Respondents</h3>
                    <p>TTR’s servers may automatically identify data related to a survey respondent, which may consist of the respondent’s domain name, IP addresses, location data, or other information about the respondent’s internet connection (“Meta Data”).</p>
                    <p>TTR may collect anonymous “Traffic Data” that does not reveal the identity of survey respondents but can be useful for improving TTR’s services. Traffic Data includes complete Uniform Resource Locators (URLs), clickstream data to, through, and from TTR’s website (including date and time), page response times, download errors, the duration of visits to specific pages, page interaction details (such as scrolling, clicks, and mouse-overs), and ways of leaving TTR’s website.</p>
                    <p>TTR utilizes cookies on its platform to enhance the experience of panelists and to perform quality control and validation functions. You have the option to decide whether to accept cookies by adjusting the settings on your browser. It is important to note that if you choose to disable cookies, your browsing experience on our website may be less optimal and some features may not function properly.</p>
                    <p>TTR may use and gather any Meta Data, Traffic Data or any other data that is not PII, which you have provided to TTR (collectively, “User Content”).</p>
                </div>

                <div>
                    <h3>Opt-Out</h3>
                    <p>At any point in time, you have the right to withdraw your consent to the collection and use of your information by TTR. This means that even if you previously agreed to provide your information, you can later choose to stop its use by TTR.</p>
                    <p>TTR respects your decision to opt out of marketing solicitations, and will only send such messages if they have obtained requisite implied or express consents as required by law. To be removed from this mailing list, you can send an email to info@thinktankresearch.co. Please note that TTR follows CASL- compliant, opt-in procedures for marketing solicitations. Any further marketing messages will include opt-out language and a mechanism for unsubscribing, such as a link that automatically unsubscribes you from receiving such messages.</p>
                </div>

                <div>
                    <h3>Protecting Respondent Information</h3>
                    <p>TTR places a high value on the privacy and security of survey respondents’ personally identifiable information and data. TTR implements industry-standard security measures to safeguard such information and data in compliance with applicable laws. Nevertheless, despite TTR’s best efforts, there is always a risk of unauthorized access to personal data. In the event of a security breach, TTR will follow all necessary procedures for reporting and remedying such incidents.</p>
                </div>

                <div>
                    <h3>Disputes Concerning Privacy Policy</h3>
                    <p>If you have any issues or concerns about how TTR has treated your personal information, please use the contact information provided below to get in touch with TTR. TTR will comply with all relevant laws and will do everything reasonable to address and resolve your concerns.</p>
                </div>

                <div>
                    <h3>Compliance</h3>
                    <p>By signing up to and participating in a TTR panel, you agree to TTR’s privacy policy and any other related terms of use. If you do not agree with our policies and practices, your choice is not to use our website or participate in a TTR panel.</p>
                </div>

                <div>
                    <h3>Contact Us</h3>
                    <p>If you have questions about this Privacy Policy, TTR’s practices related to the handling of data from survey respondents, or if you would like to have TTR remove your information from our database, please feel free to contact TTR’s Privacy Officer at:</p>
                    <p>Thinktank Research Group Inc.<br />
                        Address: 447 Broadway, 2nd Floor Suite #1637, New York – 10013 Email: contact@thinktankresearch.co</p>
                    <p>You have the right to your information/data you’ve provided TTR. It is your information. You may request a copy of the information you provide to TTR, and TTR will provide it to you in a timely manner, subject to exceptions under applicable law.</p>
                </div>

                <div>
                    <h3>Consent</h3>
                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                </div>

                <div>
                    <h3>Information we collect</h3>
                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                    <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                    <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                </div>

                <div>
                    <h3>How we use your information</h3>
                    <p>We use the information we collect in various ways, including to:</p>

                    <ul>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                    </ul>
                </div>

                <div>
                    <h3>Log Files</h3>
                    <p>ThinkTank Research Group Inc follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>
                </div>

                <div>
                    <h3>Cookies and Web Beacons</h3>
                    <p>Like any other website, ThinkTank Research Group Inc uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>
                </div>

                <div>
                    <h3>Advertising Partners Privacy Policies</h3>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of ThinkTank Research Group Inc.</p>
                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on ThinkTank Research Group Inc, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
                    <p>Note that ThinkTank Research Group Inc has no access to or control over these cookies that are used by third-party advertisers.</p>
                </div>

                <div>
                    <h3>Third Party Privacy Policies</h3>
                    <p>ThinkTank Research Group Inc’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.</p>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Privacy_Policy
