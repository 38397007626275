import React from 'react'

const Banner = (props) => {
    return (
        <div>
            <div className="banner">
                <img src={props.img} alt="banner" />
                <div className="banner-caption">
                    <h3>{props.h3}</h3>
                    <p>{props.p}</p>
                </div>
            </div>
        </div>
    )
}

export default Banner
